span {
  color: #e0e0e0;
}

.title {
  margin-bottom: 20px;
}

.icon-color {
  color: #e0e0e0;
}
